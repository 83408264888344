
h4.feature-title {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	line-height: 25px;
	padding-bottom: 5px;
	margin-bottom: 1px;
	border-bottom: solid 1px #5c5757;
}
.description-preview {
	display: inline-block;
	min-height: 56px;
	margin-bottom: 20px;
	background-color: rgba(225, 225, 225, 0.05);
	padding: 16px 12px 6px 12px;
	width: 100%;
}
[data-theme="light"] {
	.description-preview {
		background-color: rgba(0, 0, 0, 0.03);
	}
}
